import { LocationService } from './providers/location.service';
import { ThemeService } from './providers/theme.service';
import { DataServiceService } from './providers/data-service.service';
import { FirebaseService } from './providers/firebase.service';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
// import * as firebase from 'firebase';
import { MenuController, Platform, ToastController, AlertController } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Storage } from '@ionic/storage';

import { UserData } from './providers/user-data';
import { UserService } from './providers/user.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Calendar } from '@ionic-native/calendar/ngx';
// import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { environment } from './../environments/environment';
// import { FCM } from '@ionic-native/fcm/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Network } from '@ionic-native/network/ngx';

const themes = {
  autumn: {
    primary: '#F78154',
    secondary: '#4D9078',
    tertiary: '#B4436C',
    light: '#FDE8DF',
    medium: '#FCD0A2',
    dark: '#B89876'
  },
  night: {
    primary: '#8CBA80',
    secondary: '#FCFF6C',
    tertiary: '#FE5F55',
    medium: '#BCC2C7',
    dark: '#F7F7FF',
    light: '#495867'
  },
  neon: {
    primary: '#39BFBD',
    secondary: '#4CE0B3',
    tertiary: '#FF5E79',
    light: '#F4EDF2',
    medium: '#B682A5',
    dark: '#34162A'
  },
  adelaide: {
    primary: '#1996d3',
    secondary: '#8d7956',
    tertiary: '#FF5E79',
    light: '#ffffff',
    medium: '#B682A5',
    dark: '#34162A'
  },
  sydney: {
    primary: '#c3ae83', // 013668
    secondary: '#013668',
    tertiary: '#c8211b', // c8211b
    light: '#ffffff',
    medium: '#d8d8d8',
    dark: '#34162A'
  },
  darwin:  {
    primary: '#3c7a46',  
    secondary: '#ffffff',
    tertiary: '#FF5E79',
    light: '#ffffff',
    medium: '#B682A5',
    dark: '#34162A'
  },
  wol:  {
    primary: '#3c7a46',  
    secondary: '#ffffff',
    tertiary: '#FF5E79',
    light: '#ffffff',
    medium: '#B682A5',
    dark: '#34162A'
  }
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  CLIENT:string = "darwin";  
  menuList: any = [
    {
      icon: "search-outline",
      text: "Find Mass Times",
      pageURL: "/map"
    },
    {
      icon: "documents-outline",
      text: "Readings",
      pageURL: "/readings"
    }    

    // {
    //   icon: "document",
    //   text: "findy",
    //   pageURL: "http://www.google.com"
    // }
  ];

  loggedIn = false;
  dark = false;
  userName: any;
  userPhoto: any;
  appVersion: any;
  appVersionString: string = "Web";
  firebase: any;
  fcm: any;
  splashScreen: any;
  deploy: Deploy;
  isProduction: boolean = false;
  route: any;
  constructor(
    private menu: MenuController,
    private platform: Platform,
    public router: Router,
    private ss: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    public afAuth: AngularFireAuth,
    public userService: UserService,
    public av: AppVersion,
    public fb: FirebaseService,
    public d: Deploy,
    public dataService: DataServiceService,
    public theme: ThemeService,
    private oneSignal: OneSignal,
    private network: Network,
    private locationService: LocationService,
    private alertController: AlertController,
    public r: ActivatedRoute
    //private fcmX: FCM
  ) {
    this.route = r;
    // const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    // if (isIEOrEdge){
    //   alert("This doesn't work in edge or IE" + JSON.stringify(window.navigator.userAgent));
    // }
    // alert("This doesn't work in edge or IE" + JSON.stringify(window.navigator.userAgent));
    //this.changeTheme('darwin')//
    //firebase.functions().useFunctionsEmulator('http://localhost:5002') ;
    //this.fcm = fcmX;
    if (environment.production) {
      this.isProduction = true;
    }

     
    

    //console.log = function () { }  // REMOVE console.log messages with this. 
    this.deploy = d;
    this.firebase = fb;
    //this.fcm = _fcm;
    this.splashScreen = ss;
    this.appVersion = av;
    this.initializeApp();
    this.injectScripts();
    this.afAuth.authState.subscribe(user => {
      //debugger;

      // let userid = this.afAuth.auth.currentUser.uid;
      // console.log("app component ts constructor: has the following user" + this.afAuth.auth.currentUser);
      if (this.afAuth.auth.currentUser) {
        this.loggedIn = true;
        this.userName = this.afAuth.auth.currentUser.displayName;
        this.userPhoto = this.afAuth.auth.currentUser.photoURL;
      }
      else {
        this.loggedIn = false;
      }

    });


  }

  
  injectScripts(){
    


  }

  signOut() {
    this.afAuth.auth.signOut().then(() => {
      this.userService.clear();
      this.userName = "Good Giving";

      let url = '/home/' + this.dataService.getClientString();
      this.router.navigateByUrl( url);
      //location.reload();
    });
  }

  openURL(url) {
    if (url.startsWith('/')) {
      this.router.navigateByUrl(url);
    }
    else if (url.search("goodgiving.com.au") != -1)
    {
      //let isApp = !document.URL.startsWith('http');
      if (this.isMobile)
      {
        window.open(url, '_system');
      }
      else 
      {
        this.router.navigateByUrl('/home/' + this.dataService.getClientString() ) // the giving page
      }
    }
    else {
      window.open(url, '_blank');
    }
  }

  openIAP(url) {
    window.open(url, '_blank');
  }

  async updateAlert() {    
    const alert = await this.alertController.create(
      {
        header: 'New update available',
        subHeader: 'We have made some changes to the app ',
        message: 'Please click "Update"',
        backdropDismiss: false,
        buttons: [
         {
           text: 'Update', 
           handler: () => {
              this.swUpdate.activateUpdate().then( () => window.location.reload() )
              return;
          }
        }
      ]
    })   

    await alert.present();
  };

  async ngOnInit() {
    //debugger;
    window.addEventListener('offline', async (e) => {
      alert("There is no internet connection. This app is offline")
      // const alert =  this.alertController.create(
      //   {
      //     header: 'Looks like you are offline',
      //     subHeader: 'offline ',
      //     message: 'offline',
      //     buttons: [
      //      {
      //        text: 'OK', 
      //        handler: () => {
      //           this.router.navigate(['/addcard'])
      //           return;
      //       }
      //     }
      //   ]
      // })   
  
      // await alert; 
      //this.router.navigateByUrl('/offline-page'); 
    })

    window.addEventListener('online',  (e) => {
      this.router.navigateByUrl('/app-home/' + this.CLIENT );
    })

    this.route.queryParamMap.subscribe(params => {
      console.log("route params are " + JSON.stringify(params));
    })
    
    
      // let menu = this.dataService.getClientDetails()["menuList"];
      // if (menu){
      //   this.menuList.push(menu);
      // }
       

    // this.swUpdate.activateUpdate();

    // // this.listenForLoginEvents();

    // this.swUpdate.available.subscribe(async res => {
    //   const toast = await this.toastCtrl.create({
    //     message: 'Update available!',        
    //     position: 'bottom',        
    //     buttons: [
    //       {
    //         text: 'Reload',
    //         role: 'cancel',
    //         handler: () => {
    //           console.log('Close clicked');
    //           this.swUpdate.activateUpdate();
    //           window.location.reload();
    //         }
    //       }
    //     ]
    //   });

    //   await toast.present();


    //   // toast
    //   //   .onDidDismiss()
    //   //   .then(() => this.swUpdate.activateUpdate())
    //   //   .then(() => window.location.reload());
    // });
  }
  isMobile:boolean = false;
  initializeApp() {
    // Retrieve an instance of Firebase Messaging so that it can handle background
    // messages.
    //const messaging = firebase.messaging();
    this.platform.ready().then(() => {

      // hide splash screen
      this.splashScreen.hide();

      console.log("PLATFORM IS READY " + this.platform.is("ios") + this.platform.is("android")) ;
      let ios = this.platform.is("ios");
      let and = this.platform.is("android")
      let mob = ios || and;
      this.isMobile = mob;
      //alert("platform is " + this.platform.platforms() + " MOB = " + mob  +" | " + ios + "|" + and);
      if (this.platform.is("mobile") || this.platform.is("mobileweb") || this.platform.is("desktop")){
         //alert("Running on web page");
        //  this.swUpdate.checkForUpdate().then(d => {        
        //     console.info("checked for update")    
        //     this.updateAlert();
            
        //  });
         this.swUpdate.available.subscribe(event => {
            this.updateAlert();
         });
        
         // if it's on web page then bail. 
         
      }

      if (mob) {
        //alert("Running on mobile");
        this.locationService.checkLocationEnabled().then( ret => {
          if (ret) {
            this.locationService.getLocationCoordinates().then( loc => {
              // this.selectedLat = loc
              // this.selectedLon = loc.coords.longitude;
              //console.log("XXDEFAULT LOACTION IS : " + this.selectedLon + "," + this.selectedLat);
              console.log("XXDEFAULT LOACTION IS : ${J "  + JSON.stringify(loc) );
              //this.updateList();
            });;
          }  
          else {
            this.locationService.requestGPSPermission().then( x => {
              console.log("requested perission")
            })
          }
        });
  
        this.deploy.getConfiguration().then( x => {
          //alert("config is " + JSON.stringify(x));
        })
  
        this.deploy.checkForUpdate().then( (d) => {
          //alert("check for update returns "  + JSON.stringify(d));
          if (d.available) {
            this.deploy.getCurrentVersion().then(x => {
              //alert( "we have an update " + JSON.stringify(x ));
            })
  
          }
        })
  
        this.statusBar.styleDefault();
        if (this.appVersion)
        {
          this.appVersion.getAppName();
          this.appVersion.getPackageName();
          this.appVersion.getVersionCode().then( x => {
            //alert("App Version is" + JSON.stringify(x))
            this.appVersionString = JSON.stringify(x);
          });
          this.appVersion.getPackageName();
        }
  
        // adding in `onesignal` push notifications
        this.oneSignal.startInit('42ded706-039b-49e9-80de-08ee69befad0', '1054329840810');
  
        this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
  
        this.oneSignal.handleNotificationReceived().subscribe((not) => {
          // do something when notification is received
          console.log("notification recieved is " + JSON.stringify(not));
        });
  
        this.oneSignal.getIds().then( ids => {
          //alert("NOTIFICATION user id is " + ids.userId + "  pushToken  " + ids.pushToken );
        });
  
  
        this.oneSignal.handleNotificationOpened().subscribe((not) => {
          // do something when a notification is opened
          console.log("notification opened is " + JSON.stringify(not));
          //alert("notification has arrived with payload redirect.. ")
        });
  
        this.oneSignal.endInit();
  
        console.log("got to the end of initApp()");
        
        //alert("end of app block");
        // alert("calling lottie");
        // this.lottie.show('https://assets1.lottiefiles.com/packages/lf20_D8tJsf.json', false, 1024, 768)
        // .then((res: any) => alert("lottie then:" + res))
        // .catch((error: any) => alert("error" + error));
      }
      
      
    });
  }


  logout() {
    this.afAuth.auth.signOut().then((val) => {
      this.loggedIn = false;
      this.userName = null;
      this.userPhoto = null;
      this.userService.clear();
      try {
        this.afAuth.auth.currentUser = null;
      }
      catch(ex){
        console.log("error logging out")
      }
    });
  }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set('ion_did_tutorial', true);
    this.router.navigateByUrl('/tutorial');
  }

  changeTheme(name) {
    this.theme.setTheme(themes[name]);
  }
}
