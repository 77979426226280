import { ThemeService } from './theme.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClientJsonpModule, HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import { rrulestr } from 'rrule';
import * as ClientDataJSON from './clients.json';
import { CLIENT_RENEG_WINDOW, CLIENT_RENEG_LIMIT } from 'tls';
import { Observable, BehaviorSubject } from 'rxjs';
import { resolve } from 'dns';


@Injectable({
  providedIn: 'root'
})
export class DataServiceService {
  res: any;
  SERVER_URL = environment.SERVER;
  isProduction = environment.production;
  psalms: any = [
    {"psalm":"The Lord is my strength and my song", "ref":"Exodus 15:2"}, 
    {"psalm":"Seek first his kingdom and his righteousness, and all these things will be given to you", "ref":"Matthew 6:33"}, 
    {"psalm":"Those who hope in the Lord will renew their strength.", "ref":"Isaiah 40:31"}, 
    {"psalm":"Do everything in love", "ref":"1 Corinthians 16:13-14"}, 
    {"psalm":"May the God of hope fill you with all joy and peace as you trust in him", "ref":"Romans 15:13"}, 
    {"psalm":"And now these three remain: faith, hope and love. But the greatest of these is love", "ref":"1 Corinthians 13:13"}, 
    {"psalm":"Be still, and know that I am God", "ref":"Psalm 46:10"}, 
    {"psalm":"The spirit of the Lord is upon me","ref":"Isaiah 61:1 "}, 
    {"psalm":"You are the light of the world","ref":"Matthew 5:14 "}, 
    {"psalm":"With man this is impossible, but not with God; all things are possible with God", "ref":"Mark 10:27 "}
  ];
  fullParishList: any = [];  
  CLIENT_ID: number = 0;
  specialCollectionDetails:any = null;
  parishLookup: any = {};
  

  ClientData = {
    "clientList": [
            {
                "title": "Adelaide Archdiocese",
                "name": "adelaide",
                "id": 5,
                "image": "assets/img/logo.adelaide.jpg",
                theme: {
                  primary: '#1996d3',
                  secondary: '#8d7956',
                  tertiary: '#FF5E79',
                  light: '#ffffff',
                  medium: '#B682A5',
                  dark: '#34162A'
                }
            },
            {
                "title": "Darwin Diocese",
                "name": "darwin",
                "id": 15,
                "image": "assets/img/logo.darwin.png",
                theme :  {
                  primary: '#3c7a46',  
                  secondary: '#ffffff',
                  tertiary: '#FF5E79',
                  light: '#ffffff',
                  medium: '#B682A5',
                  dark: '#34162A'
                },
            },
            {
                "title": "Sydney Archdiocese",
                "name": "sydney",
                "id": 20,
                "image": "assets/img/logo.sydney.png"
            }
    ],
    "clientDetails": {
        "goodgiving": {
          "title": "",
          "id": 0,
          "image": "assets/img/logo.goodgiving.png",
          "cardURL": "",
          "cardURLPROD": "",
          "abn":"",
          "entity":""
        },
        "adelaide": {
            "title": "Adelaide Archdiocese",
            "id": 5,
            "image": "assets/img/logo.adelaide.jpg",
            "cardURL": "https://paynow-sandbox.pmnts.io/v2/TESTdexterity/ABC123/AUD/10.00/6119a6df1db4209011f6ed2e7c5b35d1?l=v2&tokenize_only=true&iframe=true&show_email=false&show_extras=false&button_text=Save&layout=v2&return_target=_self&logo_url=https://dxts.com.au/images/banner_adelaide.png&postmessage=true",
            "cardURLPROD": "https://paynow.pmnts.io/v2/catholicendowmentsa/ABC123/AUD/10.00/16a1084b672b37eb20cad960af0efd7a?l=v2&tokenize_only=true&iframe=true&show_email=false&show_extras=false&button_text=Save&return_target=_self&postmessage=true",
            "abn":"296 082 970 12",
            "entity":"Catholic Church Endowment Society Inc",
            "menuList": [
              {
                icon: "document-text-outline",
                text: "News",
                pageURL: "/news"
              },
              {
                icon: "calendar-outline",
                text: "Events",
                pageURL: "/events"
              },
              {
                icon: "globe-outline",
                text: "Diocese Website",
                pageURL: "http://www.adelaide.catholic.org.au"
              }
            ]
        },
        "darwin": {
            "title": "Darwin Diocese",
            "id": 15,
            "image": "assets/img/logo.darwin.png",
            
            "cardURL": "https://paynow-sandbox.pmnts.io/v2/TESTdexterity/ABC123/AUD/10.00/6119a6df1db4209011f6ed2e7c5b35d1?l=v2&tokenize_only=true&iframe=true&show_email=false&show_extras=false&button_text=Save&layout=v2&return_target=_self&logo_url=https://dxts.com.au/images/banner_adelaide.png&postmessage=true",
            "cardURLPROD": "https://paynow.pmnts.io/v2/CCDarwin/GoodGiving/AUD/10.00/0d0ce7fc302bae9d17169c9f018008c4?l=v2&tokenize_only=true&iframe=true&show_email=false&show_extras=false&button_text=Save&return_target=_self&postmessage=true",
            "abn":"11 296 082 970",
            "entity":"Darwin Catholic Church Fund Inc",
            "menuList": [              
              {
                icon: "globe-outline",
                text: "Diocese Website",
                pageURL: "http://www.darwin.catholic.org.au"
              }
            ]
        },
        "sydney": {
            "title": "Sydney Archdiocese",
            "id": 5,
            "image": "assets/img/logo.sydney.png",
            "cardURL": "",
            "cardURLPROD": "",
            "abn":"33 296 082 970",
            "entity":"Sydney Catholic Church Fund Inc"
        }
    }
  };

  constructor(public http:HttpClient, private firestore: AngularFirestore, public theme: ThemeService) {
    //@Inject('clientID') private clientID: number) { 

      // console.log("************* CLIENT ID IS " + clientID);
    // this.http.jsonp("https://findmass.azurewebsites.net/api/massdata/12",'callback')
    // .subscribe(res => this.res = res);

    // this.specialCollectionDetails = 
    // {
    //   ID: 1,
    //   Title: "Day of the Unborn Child",
    //   Description: "Diocesan Mass for those who die before birth. Collection for Birth line, Right to Life SA, MaterCare Australia & Genesis Pregnancy Support Inc."
    // };

    


    //this.specialCollectionDetails = null;
    // this.getSpecialCollectionZ(5).then(xxd => {
    //   if (xxd)
    //   {
    //     this.specialCollectionDetails = xxd[0];
    //   }
    //   else
    //   {
    //     this.specialCollectionDetails = null;
    //   }
    //   console.log("special collection is:" + JSON.stringify(this.specialCollectionDetails));
    // });

    

  }

  clientID: number = 0;
  clientName: string = ""; // default to adelaide

  parishObservable: Observable<Array<any>>
  setDioceseString(val) {
    console.log("DataService:setDioceseString called with " + val);
    let obj = this.ClientData.clientDetails[val]; 
    if (obj) {
        this.clientID = obj.id;
        console.log("DataService:setDioceseString client id set to  " + this.clientID);
        this.clientName = val;        
        //this.theme.setTheme(obj.theme);
        this.getParishListFB().then( (x) => {
          this.fullParishList = x;      
          console.log("loaded parish list for: " + this.clientName ) ;
          // create lookup for parishes. 
          this.fullParishList.map( (el) => {
            this.parishLookup[el.ID] = el;
        });
          
        }).catch( (y) => {
          console.log("failed to load parish list");
        });
     }
  }

  

  setParishString(val) {
    // do nothing for now. 
  }


  getClientID() {
    return this.clientID;
  }

  getClientString() {
    return this.clientName;
  }

  getClientName(idx){
    let retVal = "unknown";
    this.ClientData.clientList.forEach(element => {
      if (element.id == idx){
        retVal = element.name;
        return retVal;        
      }
    });
    return retVal;
  }

  getClientFullTitle(idx){
    let retVal = "unknown";
    this.ClientData.clientList.forEach(element => {
      if (element.id == idx){
        retVal = element.title;
        return retVal;        
      }
    });
    return retVal;
  }

  getClientTitle(){
    let retVal = "";
    let cd = this.getClientDetails();
    if (cd){
      retVal = cd.title;
    }
    return retVal;
  }

  getClientDetails() {
      return this.ClientData.clientDetails[this.clientName];
    
  }

  currentTransaction: any;
  setCurrentTransaction(trans){
    this.currentTransaction = trans;
  }
  getCurrentTransaction(){
    return this.currentTransaction;
  }

  getParishName(selectedParishID) {    
    if (this.fullParishList.length == 0 || this.fullParishList.length == 1) {
      return "?? Parish";
    }
    if (this.parishLookup == undefined){
      this.parishLookup = {};
      // create the map if it doesn't exist.
      this.fullParishList.map( (el) => {
        this.parishLookup[el.ID] = el;
      });
    }
    return this.parishLookup[selectedParishID].name;
  }

  
 
  // This method will return the URL for the card iFrame. The url will depend on the clientID. 
  getCardURL()
  {
    try 
    {
    //console.log("######in getCARDURL" + this.clientName);
    let clientDetails = this.getClientDetails();
    if (clientDetails)
      {
      console.log("Retieving CC form PROD:" + this.isProduction + " and client " + clientDetails.entity );

      if (this.isProduction){
        return clientDetails["cardURLPROD"];
      }
      else
      {
        return clientDetails["cardURL"];
      }
    }
    else
    {
      console.log("Failed to get add card form");
    }
    } catch(ex) {
      new Error("APP: User couldn't get cardURL for client " + this.clientName + " ex + " + JSON.stringify(ex) )
      console.log("APP: User couldn't get cardURL for client " + this.clientName + " ex + " + JSON.stringify(ex))
    }
  }

  getRandomPsalm(){
    let ran = Math.floor(Math.random() * this.psalms.length);
    return this.psalms[ran];
  }
  parishListData:any = {};    

  getParishListFB(){
    // returns the parishes for the diocese. 
    console.log("Getting parishes for " + this.clientName);
    

    return new Promise((resolve, reject) => {
      
      let oldData = this.parishListData[this.clientName];
      
      console.log("old data is " + oldData);
      if (oldData) {
        resolve(oldData);
        return; // no need to continue
      }

      var docRef = this.firestore.collection("appdata").doc(this.clientName);

      docRef.get().subscribe(function(doc) {
          if (doc.exists) {
              //console.log("Document data:", doc.data());
              let data = doc.data();
              localStorage.setItem("parishes", JSON.stringify(data.parishes));
              localStorage.setItem("special", JSON.stringify(data.special));
              this.specialCollectionDetails = data.special;
              resolve(data.parishes)
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
              reject("no such document. failed to retrieve app data");
          }
      });
    });
  }

  getSpecialCollection(){
    let sp = localStorage.getItem("special")
    if (sp == "{}") {
      return null;
    }
    this.specialCollectionDetails = JSON.parse(sp);
    return this.specialCollectionDetails;
    
  }

  getSpecialCollectionZ(dioceseId) {
    return new Promise((resolve, reject) => {     
      resolve(this.fullParishList) ;
      
      let url= this.SERVER_URL + "v1/collections/other/5";
      
      //let header = this.getHeaders(token);
      let data = null;
      console.log("getting parish list from server")        ;
      this.http.get(url,  {headers: new HttpHeaders({
        'Content-Type':  'application/json'
        //'Authorization': this.authToken
      })
      }).subscribe(res => {           
        console.log("Got third collection " + JSON.stringify(res)) ;        
        if(res == 0){
          resolve(null);
          return;
        }
        resolve(res[0]);      
      });
    });
  }

  injectGoogleMaps() {
  const script = document.createElement('script');
      script.id = 'googleMap';
      script.src = "https://maps.google.com/maps/api/js?key=AIzaSyA5WKgYcEULnouS0-Go6TivtX4sq95qxxc&libraries=places,visualization,drawing,geometry,places&sensor=true";      
      script.type = 'text/javascript';
      script.async = true;
      script.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(script);
  }

  injectZendesk() {
    console.log("injecting zendex");
    const ZDscript =  document.createElement('script');
      ZDscript.id = "ze-snippet";
      ZDscript.src="https://static.zdassets.com/ekr/snippet.js?key=2b559baa-e886-4c62-ab1e-7ee8d4824cf7";
      ZDscript.type = 'text/javascript';
      ZDscript.async = true;
      ZDscript.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(ZDscript);

      console.log("injectin zendex done");
  }

  getCurrentSpecialCollection(dioceseId){
    //return null into contract. 
    return null;
  }

  convertUTCToLocal(date,time){
    // Split timestamp into [ Y, M, D, h, m, s ]
    let theDate = date.substr(0,10) + " " + time;
    var t = theDate.split(/[- :]/);
    // Apply each element to the Date function
    var d = new Date(Date.UTC(parseInt(t[0]), parseInt(t[1])-1, parseInt(t[2]), parseInt(t[3]), parseInt(t[4]), parseInt(t[5])));

    let mom = moment(d);
    console.log(d);
    return mom.toLocaleString();

    
  }

  getFriendlyNextChargeString(rruleStr: any, plusOne?: boolean) {
    let today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    
    console.time("Time this");
    let nextDate;
    
    if (plusOne) 
    {
      nextDate = rrulestr(rruleStr).after(tomorrow);
    }
    else 
    {
      nextDate = rrulestr(rruleStr).after(today);
    }
    
    console.log(nextDate);
    let now = moment().hour(0).min(0);
    let next = moment(nextDate);
    let friendlyDate = (next.format("Do MMM"));
    let diff = next.diff(now, 'days'); // 1
    let nextString = "Next payment in " + diff + " days (" + friendlyDate + ")";
    if (diff == 0) {
      nextString = "Next payment is today (" + friendlyDate + ")";
    }
    if (diff == 1) {
      nextString = "Next payment is tomorrow (" + friendlyDate + ")";
    }
    if (diff > 30) {
      let newDiff = next.diff(now, 'weeks');
      nextString = "Next payment in " + newDiff + " weeks (" + friendlyDate + ")";
    }
    return nextString;
  }

  
  
    
}
