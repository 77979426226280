import { Injectable } from '@angular/core';
// import { HttpClient, Jsonp } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { HttpClientJsonpModule, HttpClientModule, JsonpClientBackend   } from '@angular/common/http';
//import {JsonpModule } from '@angular/common/http';
//iimport {JsonpModule, Jsonp, Response} from '@angular/common/http';
import { List } from 'linqts';

// How to get JSONP calls working in ionic 4
// https://stackoverflow.com/questions/52797533/getting-json-data-using-jsonp-from-flickr-api

@Injectable({
  providedIn: 'root'
})
export class FindMassService {
  
  massData: any;
  myList: any ; 
  dataLoaded: boolean = false;
  online: string ="https://findmass.azurewebsites.net";
  currentLat:number =  -34.92906;
  currentLon:number = 138.60142;

    constructor(private http : HttpClient) { 
      // console.count("FindMassService constructor");
      this.getAllData()
        .subscribe(res => {
          this.massData = res;
          this.dataLoaded = true;
          //alert("data loaded");
        });

      // console.log("CALLING JSONP");
      // this.http.jsonp("https://findmass.azurewebsites.net/api/massdata/12?","callback")
      // .subscribe(res => {
      //   console.log(res)
      // });        
    }

    setCurrentPos(latitude, longitude){
      this.currentLat = latitude;
      this.currentLon = longitude;
    }

    getLat() {
      return this.currentLat;
    }

    getLon() {
      return this.currentLon;
    }

    getChurchDetails(id){
      this.myList = new List(this.massData);
      return this.myList.Where(x => x.ChurchID == id).ToArray();
    }

    getDataFromServer(lat, lon, day, hour, min) : any {
      var createGetURL = this.online + "/api/masstimes/" + lat + "/" + lon + "/" + day + "/" + hour + "/" + min ;
      return this.http.jsonp(createGetURL, 'callback');
    }

    getNearestDataFromServer(lat, lon, day) : any{
      var createGetURL = this.online + "/api/masstimes/" + lat + "/" + lon + "/" + day ;
      return this.http.jsonp(createGetURL, 'callback');
      //return $http.jsonp(createGetURL);
    }

    getNearestDataFromServerWorship (lat, lon, day, wortype): any {
      if (wortype == null)
      {
        return this.getNearestDataFromServer(lat,lon,day);
      }
      var createGetURL = this.online + "/api/masstimes/" + lat + "/" + lon + "/" + day + "/" + wortype ;
      return this.http.jsonp(createGetURL, 'callback');
    }

    getAllMassTimes(): any {
      var createGetURL = this.online + "/api/masstimes/" ;
      return this.http.jsonp(createGetURL, 'callback');
    }

    getAllChurches(): any {
      var createGetURL = this.online + "/api/parishes/" ;
      return this.http.jsonp(createGetURL, 'callback');
    }

    getAllData(): any {
      var createGetURL = this.online + "/api/massdata/" ;
      return this.http.jsonp(createGetURL, 'callback');
    }
    
    getDay(dayInt): string {
      if (dayInt == 1) {
        return "Mon";
      }
      else if (dayInt == 2) {
        return "Tue";
      }
      else if (dayInt == 3) {
        return "Wed";
      }
      else if (dayInt == 4) {
        return "Thu";
      }
      else if (dayInt == 5) {
        return "Fri";
      }
      else if (dayInt == 6) {
        return "Sat";
      }
      else if (dayInt == 0) {
        return "Sun";
      }
      else {
        console.log('error converting day input was ' + dayInt);
        return "???";
      }
    }


  getNearest(){
    const dataUrl = 'https://findmass.azurewebsites.net/api/massdata/12';
    // Prepare the request
    return this.http.jsonp(dataUrl, 'callback');
  }

  // getChurchDetails(churchID){   
  //   this.http.jsonp("http://findmass.azurewebsites.net/api/massdata/12",'callback')
  //       .subscribe(res => this.massData = res);

  //   const dataUrl = 'http://findmass.azurewebsites.net/api/massdata/12';
  //   const promise = new Promise((resolve, reject) => {
  //     const URL = dataUrl;
  //     this.http
  //       .jsonp(URL, 'callback')
  //       .toPromise()
  //       .then((res: any) => {
  //         this.massData = res;
  //         resolve(res);
  //         },
  //         err => {
  //           reject(err);
  //         }
  //       );
  //   });
  //   return promise;
  // }


}
