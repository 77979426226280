import { Injectable } from '@angular/core';
// import { FirebaseX } from "@ionic-native/firebase-x/ngx";

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  //firebase: FirebaseX;
  constructor(
    //public fb: FirebaseX
    ) { 
    
    // this.firebase = fb;
    
    // this.firebase.getToken().then(token => alert(`The token is ${token}`))
    
    // this.firebase.onMessageReceived().subscribe(data => alert(`FCM message: ${data}`));
  }

  getToken(){
    
    // this.firebase.getToken().then(token => alert(`The token is ${token}`));
  }
}
