import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';

import { FindMassService } from './providers/find-mass.service';
import { PaymethodComponent } from './component/pay/paymethod/paymethod.component';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import {FirebaseUIModule, firebase, firebaseui} from 'firebaseui-angular';

import {AngularFireModule} from '@angular/fire'; 
import {AngularFireAuthModule} from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';



import { FormsModule } from '@angular/forms';

// plugins
import { AppRate } from '@ionic-native/app-rate/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Calendar } from '@ionic-native/calendar/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Network } from '@ionic-native/network/ngx';


// import { FCM } from '@ionic-native/fcm/ngx';
// import { FirebaseMessaging} from "@ionic-native/firebase-messaging/ngx";
export function redirectFunction (authResult, redirectUrl) {
  return false;
}
const firebaseUiAuthConfig: firebaseui.auth.Config = {
  
     callbacks: {
      signInSuccessWithAuthResult: redirectFunction
      // signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      //   // User successfully signed in.
      //   // Return type determines whether we continue the redirect automatically
      //   // or whether we leave that to developer to handle.
      //   alert("singed in succesfully->" + redirectUrl + " aR=" + authResult);
      //   this.router.navigateByUrl('/home');
      //   return true;
      // }
      // uiShown: function() {
      //   // The widget is rendered.
      //   // Hide the loader.
      //   //document.getElementById('loader').style.display = 'none';
      // }     
    },
  signInSuccessUrl: null, //window.location.href.toString().includes("tutorial") ? null : "/home",
  signInFlow: 'popup',
  signInOptions: [
    // {
    //   provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    //   whitelistedCountries: ['AU', '+61']
    // },
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // {
    //   provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    //   scopes: [
    //     'public_profile',
    //     'email'
    //   ]
    // },
    // firebase.auth.GoogleAuthProvider.PROVIDER_ID    
  ],
  tosUrl: '/tos',
  privacyPolicyUrl: '/privacy-policy',
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
};


@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientJsonpModule, HttpClientModule,
    FormsModule,
  //  SharedModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    AngularFireModule.initializeApp(environment.firebase),        
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,    
    FirebaseUIModule.forRoot(firebaseUiAuthConfig)        
  ],
  entryComponents: [
  ],
  declarations: [AppComponent, PaymethodComponent],
  providers: [InAppBrowser, SplashScreen, StatusBar, FindMassService, 
              AppRate, CallNumber, AppVersion, Calendar, EmailComposer, 
              LaunchNavigator, Deploy, OneSignal, AndroidPermissions, LocationAccuracy, Diagnostic, Geolocation, Network ],
  bootstrap: [AppComponent]
})
export class AppModule {}
