import { AuthGuardService } from './providers/auth-guard.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckTutorial } from './providers/check-tutorial.service';

const APP_CLIENT = "darwin";
const routes: Routes = [
  // {
  //   path: '**',
  //   redirectTo: '/offline-page'
  // },
  
  {
    path: '',
    redirectTo: '/app-home/' + APP_CLIENT,
    pathMatch: 'full'
  }, 
  {
    path: 'app',
    redirectTo: '/app-home/' + APP_CLIENT,
    pathMatch: 'full'
  },    
  {
    path: 'adelaide',
    redirectTo: '/home/adelaide',
    //redirectTo: 'maintenance',
    pathMatch: 'full'
  },
  {
    path: 'Adelaide',
    redirectTo: '/home/adelaide',
    //redirectTo: 'maintenance',
    pathMatch: 'full'
  },
  // {
  //   path: 'darwin',
  //   redirectTo: '/home/darwin',
  //   //redirectTo: 'maintenance',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'Darwin',
  //   redirectTo: '/home/darwin',
  //   //redirectTo: 'maintenance',
  //   pathMatch: 'full'
  // },
  // {
  //   path: ':diocese',
  //   loadChildren: () => import('./pages/to/to.module').then( m => m.ToPageModule)
  // },
  // {
  //   path: ':diocese/:parish',
  //   loadChildren: () => import('./pages/to/to.module').then( m => m.ToPageModule)
  // },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/map/map.module').then(m => m.MapModule)
  },
  {
    path: 'tutorial',    
    //Component: TutorialModule
    loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule)
    //,canActivate: [AuthGuardService]
  },  
  {
    path: 'listpaymethods',
    loadChildren: () => import('./pages/pay/listpaymethods/listpaymethods.module').then( m => m.ListpaymethodsPageModule)
  },
  {
    path: 'addcard',
    loadChildren: () => import('./pages/pay/addcard/addcard.module').then( m => m.AddcardPageModule)
  },
  {
    path: 'addcard/:ret',
    loadChildren: () => import('./pages/pay/addcard/addcard.module').then( m => m.AddcardPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home/:diocese',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home/:parish',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'confirmpayment/:churchid/:churchval/:dioceseid/:dioceseval/:thirdid/:thirdval/:total/:token',
    loadChildren: () => import('./pages/confirmpayment/confirmpayment.module').then( m => m.ConfirmpaymentPageModule)
  },
  {
    path: 'givenow/:type',
    loadChildren: () => import('./pages/givenow/givenow.module').then( m => m.GivenowPageModule)
  },
  {
    path: 'readings',
    loadChildren: () => import('./pages/readings/readings.module').then( m => m.ReadingsPageModule)
  },
  {
    path: 'church-details/:churchid',
    loadChildren: () => import('./pages/church-details/church-details.module').then( m => m.ChurchDetailsPageModule)
  },
  {
    path: 'app-home/:diocese',
    loadChildren: () => import('./pages/app-home/app-home.module').then( m => m.AppHomePageModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/events/events.module').then( m => m.EventsPageModule)
  },
  {
    path: 'podcasts',
    loadChildren: () => import('./pages/podcasts/podcasts.module').then( m => m.PodcastsPageModule)
  },
  {
    path: 'legals',
    loadChildren: () => import('./pages/legals/legals.module').then( m => m.LegalsPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'tos',
    loadChildren: () => import('./pages/tos/tos.module').then( m => m.TosPageModule)
  },
  {
    path: 'to',
    loadChildren: () => import('./pages/to/to.module').then( m => m.ToPageModule)
  },
  {
    path: 'to/:diocese',
    loadChildren: () => import('./pages/to/to.module').then( m => m.ToPageModule)
  },
  {
    path: 'to/:diocese/:parish',
    loadChildren: () => import('./pages/to/to.module').then( m => m.ToPageModule)
  },
  {
    path: 'transaction-summary-page',
    loadChildren: () => import('./pages/transaction-summary-page/transaction-summary-page.module').then( m => m.TransactionSummaryPagePageModule)
  },
  {
    path: 'media',
    loadChildren: () => import('./pages/media/media.module').then( m => m.MediaPageModule)
  },
  {
    path: 'offline-page',
    loadChildren: () => import('./pages/offline-page/offline-page.module').then( m => m.OfflinePagePageModule)
  },
  {
    path: 'welcome-tutorial',
    loadChildren: () => import('./pages/welcome-tutorial/welcome-tutorial.module').then( m => m.WelcomeTutorialPageModule)
  },
  {
    path: 'error-page',
    loadChildren: () => import('./pages/error-page/error-page.module').then( m => m.ErrorPagePageModule)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./pages/maintenance/maintenance.module').then( m => m.MaintenancePageModule)
  },
  {
    path: 'giving-welcome',
    loadChildren: () => import('./pages/giving-welcome/giving-welcome.module').then( m => m.GivingWelcomePageModule)
  },
  { path: '**', 
    redirectTo: '/app'
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./pages/blog/blog.module').then( m => m.BlogPageModule)
  }



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
