import { LoadingController } from '@ionic/angular';
import { DataServiceService } from './data-service.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { RecurringOptionsComponent } from './../component/recurring-options/recurring-options.component';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { firebase } from 'firebaseui-angular';

import { UserFB, Donation } from './../interfaces/user-options';
import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';
import { NavigationExtras, Router } from '@angular/router';
 
@Injectable({
  providedIn: 'root'
})
export class UserService {

  theUser: UserFB = {
    username: "",
    password: "",
    name: "",
    surname: "",
    number: "",
    address: "",
    parish: "",
    parishDetails: {},
    email: "",
    clientId: 0, 
    selectedCC: "",
    selectedExpiry: "",
    cclist: [],
    transactions: [],
    pending: [],
    recurring: ""
  };

  tempDonation: {
    // build up donation details
    rrule:"",
    recurringStartDate:"",    
    recurringType: "";
    dioceseId: 5;
    parishId:  0;
    parishValue:  5;
    dioceseValue: 5;
    thirdId: 0;
    thirdValue:  0;
    total: -1;
    clientId: 0;
    selectedParishId:0,
    otherId: 0
  }

  http: HttpClient;
  SERVER_URL = environment.SERVER;

  constructor(
    private firestore: AngularFirestore,
    public afAuth: AngularFireAuth,
    public ht: HttpClient,
    public router: Router,
    public dataService: DataServiceService,
    public loadingController: LoadingController
  ) { 
    this.http = ht;
    //debugger;
    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@  server is " + this.SERVER_URL)
  }
 
  create_update_User(userid, record){
      return this.firestore.collection('users').doc(userid).set(record,  {"merge": true});
  }

  clear() {
    window.localStorage.removeItem("currentUser");
    this.theUser = {
      username: "",
      password: "",
      name: "",
      surname: "",
      number: "",
      address: "",
      parish: "",
      parishDetails: {},
      email: "",
      clientId: 0, 
      selectedCC: null,
      selectedExpiry: "",
      cclist: [],
      transactions: [],
      pending: [],
      recurring: ""
    };


    this.tempDonation =  {
      // build up donation details
      rrule: "",      
      recurringStartDate:"",
      recurringType: "",
      dioceseId: 5,
      parishId:  0,
      parishValue:  5,
      dioceseValue: 5,
      thirdId: 0,
      thirdValue:  0,
      total: -1,
      clientId: 0,
      selectedParishId: 0,
      otherId:0
    }
  }
  private authToken: string = null;
  setToken(tok) {
    this.authToken = tok;
  }


  setDonation(don) {

    this.tempDonation = don;
    
  }

  getDonation() {
    return this.tempDonation;
  }

  setClientId(val) {
    this.theUser.clientId = val;
    this.updateLocal();
  }

  getClientId() {
    return this.theUser.clientId;
  }

  saveDonation(userid, sqlId, don) {
    don["sqlId"] = sqlId;
    var userRef = this.firestore.collection("users").doc(userid);    
      return userRef.update({
        recurring: don
      });    
  }
  deleteRecurring(userid) {
    var userRef = this.firestore.collection("users").doc(userid);
    return userRef.update({
      recurring: null
    });    
  }

  get_User_local() {
    let userData = window.localStorage.getItem("currentUser");
    if (userData != null)
    {
      return JSON.parse(userData);
    }
    else {
      return null;
    }
  }

  get_User_selectedCC() {
    
    let usr = this.get_User_local()
    if (usr) 
    {
      return usr.selectedCC;
    }
    return null;
  }

  get_user_parishDetails() {
    let usr = this.get_User_local()
    if (usr) 
    {
      return usr.parishDetails;
    }
    return null;
  }

  updateLocal() {
    window.localStorage.setItem("currentUser", JSON.stringify(this.theUser));  
  }

  loading:any;
  async presentLoading() {
   this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...setting up your account'    
    });
    await this.loading.present();

    const { role, data } = await this.loading.onDidDismiss();
    console.log('Loading dismissed!');
  }

  load_User(userid, callback){
    try {      
    var docRef = this.firestore.collection("users").doc(userid);
    docRef.ref.get().then(doc => {
      let d = doc.data();  
      if (d) {
        this.theUser = {
          username: "",
          password: "",
          name: d.name,
          surname: d.surname,
          number: d.number,
          address: d.address,
          parish: d.parish,
          parishDetails: d.parishDetails,
          email: d.email,
          clientId: d.clientId, 
          selectedCC: d.selectedCC,
          selectedExpiry: d.selectedExpiry ? d.selectedExpiry:"",
          cclist: d.cclist,
          transactions: d.transactions,
          pending: d.pending,
          recurring: d.recurring
        } ;
        console.log("load_User: CARD COUNT LIST: " + this.theUser.cclist.length);
        window.localStorage.setItem("currentUser", JSON.stringify(this.theUser));   
        
        callback(d);
      }
      else {

          console.error("Unable to load user " + userid + " Possilbe cause - the user was just created. Creating user now");
          if (this.loading) {
            this.presentLoading();
          }
          // creating the user if they doesn't exist. 
          let userObj = {
            username: "",
            password: "",
            name: this.afAuth.auth.currentUser.displayName,
            surname: "",
            number: "",
            address: "",
            parish: "",
            email: this.afAuth.auth.currentUser.email,
            clientId: this.dataService.getClientID(), 
            selectedCC: "",
            selectedExpiry:"",
            cclist: [],
            transactions: [],
            pending: [],
            allrecurring: [],
            currentrecurring: ""
          };
          
          this.create_update_User(userid, userObj).then( (x) => {
            if (this.loading){
              this.loading.dismiss();
            }
            callback(userObj);
            return;
          })

        
      }
      

    });
    }catch(err) {
      this.loadErrorPage("Error loading user details", "Please contact support with the following details :" + err + ". " + window.location , null);
    }
    
  }
 
  

  loadErrorPage(tit, desc, path){
    let def = "mail-to:support@dxts.com.au"; 
    if (path == null || path == undefined){
      path = def;
    }
    let navigationExtras: NavigationExtras = {
      state: {
        title: tit,
        description: desc,
        url: path              
      }
    };
      
    this.router.navigate(['error-page'], navigationExtras);
  }

  update_User(recordID,record){
    this.firestore.doc('users/' + recordID).update(record);
  }
 
  delete_User(record_id) {
    this.firestore.doc('users/' + record_id).delete();
  }

  set_User_parish(userid, client, parishObj){
    this.theUser.parishDetails = parishObj;
    this.theUser.clientId = client;
    this.updateLocal();
    var userRef = this.firestore.collection("users").doc(userid);
      return userRef.update({
        parishDetails: parishObj
        //,clientId: client
      });
  }

  set_User_Client(userid, client){    
    this.theUser.clientId = client;
    this.updateLocal();
    var userRef = this.firestore.collection("users").doc(userid);
      return userRef.update({
        clientId: client
        //,clientId: client
      });
  }
  
  getExpiryDateFromCardList(cardToken, cardList){
    cardList.forEach(element => {
      if (element.token == cardToken){
        // we have a match
        return element.card_expiry;
      }
    });
    return null;
  }

  set_User_defaultCCToken(userid, cctoken) {

    return new Promise((resolve, reject) => {
      try
      {
          this.theUser.selectedCC = cctoken;
          this.updateLocal();
          var userRef = this.firestore.collection("users").doc(userid);

          // if recurring data exists.. 
          userRef.get().subscribe(ref => {
            let myData = ref.data();
            if (myData){                            
              if (myData.recurring) 
              {
                let recID = myData.recurring.sqlId;
                if (this.authToken)
                {          
                  let data = {
                    ID: recID,
                    userID: userid,
                    newCC: cctoken
                  }
                  let url= this.SERVER_URL + "v1/transactions/recurring/updateCC";              
                  
                  this.http.post(url, data, {headers: new HttpHeaders({
                    'Content-Type':  'application/json',
                    'Authorization': this.authToken
                  })
                  }).subscribe(res => {           
                    console.log(res) ;
                      userRef.update({
                        selectedCC: cctoken,
                        selectedExpiry: this.getExpiryDateFromCardList(cctoken, myData.cclist)
                      }).then(x => {
                         resolve(true);
                      }).catch(y => {
                         reject("saved in database, but not in app (firestore) " + y);
                      })                                    
                  });
                } else {
                  alert("authorised token missing - are you logged in? Please login and try again.");
                  reject(
                    "authorised token missing - are you logged in? Please login and try again."
                  )
  
                }
              }
              else 
              {
                userRef.update({
                        selectedCC: cctoken,
                        selectedExpiry: this.getExpiryDateFromCardList(cctoken, myData.cclist)
                      }).then(x => {
                         resolve(true);
                      }).catch(y => {
                         reject("failed to update app (firestore) " + y);
                      });      
              }
            }
          })
        }catch (ex) {
          reject("failed up update default credit card:" + ex);
        }
    });
        
    //    this.selectedCC = cctoken;    
    
  }

  delete_CC(userid, ccObj, callback) {
    var userRef = this.firestore.collection("users").doc(userid);
    return userRef.update({
      cclist: firebase.firestore.FieldValue.arrayRemove(ccObj)      
    }).then((x) => {
      var docRef = this.firestore.collection("users").doc(userid);
      docRef.ref.get().then(doc => {
        let d = doc.data();  
        this.theUser = {
          username: "",
          password: "",
          name: d.name,
          surname: d.surname,
          number: d.number,
          address: d.address,
          parish: d.parish,
          parishDetails: d.parishDetails,
          email: d.email,
          clientId: d.diocese, 
          selectedCC: d.selectedCC,
          selectedExpiry: d.selectedExpiry ? d.selectedExpiry:"",
          cclist: d.cclist,
          transactions: d.transactions,
          pending: d.pending,
          recurring: d.recurring
        } ;
        window.localStorage.setItem("currentUser", JSON.stringify(this.theUser));            
        callback(d.cclist);
    });
    });
  }

  getUserTransactionsAPI(limit, token,callback){
    //let data = {"ID":824,"DioceseID":"100014022","DioceseValue":10,"recID":null,"ParishID":"100000163","ParishValue":4,"ThirdID":"100013525","ThirdValue":0,"GatewayTransID":"8388-P-G58S0DHT","Fee":0,"GatewayTransDate":"2020-02-26T13:30:00.000Z","GatewayTransTime":"15:20:22","userID":"FiAr65LcR9gx4E1waoDYwv4577v1","responseCode":"00","email":"ginilfernandez@gmail.com","ClientID":5}
    //this.setRecurring(data, token, (bla) => {
      //console.log("returned from set recurring" + bla); 

      let re = null;
      let url= this.SERVER_URL + "v1/transactions/" + limit;

      console.log("Getting user transactions usring the URL " + this.SERVER_URL);
      //let header = this.getHeaders(token);
      this.http.get(url, {
        headers: new HttpHeaders(
              {
                'Content-Type':  'application/json',
                'Authorization': token
              }
      )
      }).subscribe(res => {            
        callback(res);      
      });

    //})    
  }

  getHeaders(token){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
  }

  getUserTransactions(limit, callback) {
    let cloudFn = firebase.functions().httpsCallable('getTransactionsForUser');

    let dataToSend = {
      "limit":5
    }
    cloudFn(dataToSend).then((result) => { 
      console.log("got back user transactions ", result);
      callback(result);
    });
  }

  isCorrectDiocese(userID, dioceseID, callback) {
    this.load_User(userID, (d)=> {
      if (d.clientId == 0 && dioceseID != 0){
        // user hasn't been properly setup. 
        this.set_User_Client(userID, dioceseID);
        callback(true);
      } 
      else if (d.clientId != dioceseID) {
        callback(false);
      }
      else {
        callback(true)
      }
    })
  }

  hasRecurring(uid, callback) {
    this.load_User(uid, (x) => {
      if (x == null) {
        // user not created or doesn't exist
        callback(false);
        return;
      }
      let has = true;
      if (x.recurring == "" || x.recurring == null || x.recurring == undefined || x.recurring == []){
        has = false;
      }
      callback(has);
      return;
    })
  }

  setRecurring(data, callback) {
    
    //let datax = {"ID":824,"DioceseID":"100014022","DioceseValue":10,"recID":null,"ParishID":"100000163","ParishValue":4,"ThirdID":"100013525","ThirdValue":0,"GatewayTransID":"8388-P-G58S0DHT","Fee":0,"GatewayTransDate":"2020-02-26T13:30:00.000Z","GatewayTransTime":"15:20:22","userID":"FiAr65LcR9gx4E1waoDYwv4577v1","responseCode":"00","email":"ginilfernandez@gmail.com","ClientID":5}
    if (this.authToken)
    {          
      if (data.token.length < 1){
        console.error("no token.. trying to get users old token..");
        data.token = this.get_User_selectedCC();

        if (data.token.length < 1){
          console.error("no token.. failed to get users old token..");
           callback("cardError");
        }
      }

      let re = null;
      let url= this.SERVER_URL + "v1/transactions/recurring/";
      console.log("userService.setRecurring called with " + JSON.stringify(data));
      //let header = this.getHeaders(token);
      this.http.post(url, data, {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.authToken
      })
      }).subscribe(res => {           
        console.log(res) ;
        callback(res);      
      });
    } else {
      alert("authorised token missing - are you logged in? Please login and try again.");
    }
  }

  deleteRecurringOnServer(uid, callback) {
    if (this.authToken)
    {          
      let re = null;
      let url= this.SERVER_URL + "v1/transactions/recurring/";
      
      //let header = this.getHeaders(token);
      let data = { user_ID: uid };
      console.log("userService.setRecurring called with " + JSON.stringify(data));
      this.http.put(url, data, {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.authToken
      })
      }).subscribe(res => {           
        console.log(res) ;
        callback(res);      
      });
    } else {
      alert("authorised token missing - are you logged in? Please login and try again.");
    }
  }
    

}